import logo from './static/images/under-construction-icon.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <code>Grupo Carvasol.</code>
        </p>
        <img src={logo} className="App-logo" alt="Grupo Carvasol" />
        <p>Sitio en construcción</p>
      </header>
    </div>
  );
}

export default App;
